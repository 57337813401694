<template>
  <main class="col-md-9 ms-sm-auto col-lg-10  px-md-4">
    <div class="container mt-5 mb-1 pb-2" id="custom-cards">
      <h3 class="pb-2 border-bottom text-dark-blue">Payments</h3>
      <!-- group inputs -->
      <div class="row col-md-12 justify-content-between">
        <div class="col-6 text-start align-middle">
          <label for=""> Member</label>
          <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers" @select="SelectedMember" @clear="ClearMember" class="size-multiselect">
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div class="multiselect-tag is-user">
                <img :src="option.icon">
                <a :style="{'color':option.color}">{{ option.label }}</a>
                <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </div>
            </template>
            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon"> {{ option.label }}
            </template>
          </Multiselect>
        </div>       
        <div class="col-4 input-group-sm text-start align-middle">
          <label for=""> Date</label>
          <v-date-picker v-model="range" :model-config="modelConfig" is-range @dayclick="onDayClick" :max-date='new Date(new Date().getTime() - (24*60*60*1000))'>
            <template v-slot="{ inputValue, inputEvents }">
              <input class="form-control input-h bg-white input-group-lg border px-2 py-1 rounded" :value="inputValue.start" v-on="inputEvents.start" />
            </template>
          </v-date-picker>
          <input id="counterSelect" style="visibility:hidden" type="text" value="">
          <input id="totalProjects" style="visibility:hidden" type="text" value="">
        </div>
        <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mt-5 mb-5 mt-0 bg-body rounded" v-if="ListSelect.status != 'false'">       
          <div class="table-responsive">            
            <table class="table table-striped responsive" id="pay-table">
              <thead>
                <tr class="text-start">
                  <th style="display: none;">
                    <!--<span class="text-blue">
                    <input type="checkbox" data-bs-toggle="modal" data-bs-target="#staticB" value="" id="AllCheck"></span>-->
                  </th>
                  <th scope="col">
                    <span class="text-blue">Project</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Time</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Rate</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Earned</span>
                  </th>      
                </tr>
              </thead>
              <tbody class="mb-special">
                <tr class="text-start" v-if="ListSelect.status != 'false'" v-for="(obj, key, index) in ListSelect.list" :key="index">
                  <!--<td class="text-start"><input class="form-check-input" type="checkbox" value="" v-bind:id="index" @click="boton=!boton"></td>-->
                  <td style="display: none;">{{obj.id_project}}</td>
                  <td class="text-start"><strong>{{obj.project}}</strong></td>
                  <td class="text-start"><strong>{{obj.seconds}}</strong></td>
                  <td class="text-start"><strong>{{obj.pay_rate}}</strong></td>
                  <td class="text-start"><strong>{{obj.payment}}$</strong></td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="text-start">
                  <td style="display: none;"></td>
                  <td class="text-start text-blue"> <strong>Total</strong> </td>
                  <td></td>
                  <td></td>
                  <td class="text-start text-blue"> <strong>{{SumTotalProjects()}}</strong> </td>
                </tr>
              </tfoot>
            </table>
            <!--<div v-show="boton">-->
            <div id="button-confirm" style="visibility:hidden;">
              <button class="button-1 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#confirmarSeleccion">Confirm</button>
            </div>
          </div>
        </div>
        <!--MAS DE UN PROYECTO SELECCIONADO-->
        <!--Modal DE CONFIRMACION para para cuando se seleccionta dos o más varios projectos-->        
        <div class="modal fade" id="confirmarSeleccion"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="confirmarSeleccion" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="confirmarSeleccion">Confirm Selection </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">
                    <div class="col-md-12"> 
                      <div>
                        <h5>Are you sure you want to start the process with these projects?</h5> 
                        <button class="button-2 btn btn-primary m-1" @click="ConfirmProjects"  data-bs-dismiss="modal">Confirm</button>
                        <button type="btn btn-secondary" class="btn btn-secondary m-3" data-bs-dismiss="modal" >Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End modal -->  
        <!-- Tabla para cuando se seleccionta dos o más varios projectos -->
        <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mb-2 mt-2 bg-body rounded" v-if="ListPayments.status != 'false'"><!--v-show="mostrarvarios"-->
          <div class="table-responsive">
            <table class="table table-striped responsive" id="pay2-table">
              <thead>
                <tr class="text-start">
                  <th scope="col"></th>
                  <th scope="col">
                    <span class="text-blue">User</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Date from</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Date to</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Time</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Earned</span>
                  </th>                   
                  <th scope="col" class="text-center">
                    <span class="text-blue">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody class="mb-special align-middle">               
                <tr class="text-start" v-for="(objLP, keyLP, indexLP) in ListPayments.list" :key="indexLP">
                  <td class="text-start"><img :src="objLP.picture" alt="" width="38" height="38" class="img-avatar"></td>
                  <td class="text-start"><strong>{{objLP.user}}</strong></td>
                  <td class="text-start"><strong>{{objLP.date_init}}</strong></td>
                  <td class="text-start"><strong>{{objLP.date_finish}}</strong></td>
                  <td class="text-start"><strong>{{objLP.time_worked}}</strong></td>
                  <td class="text-start"><strong>{{objLP.payment}}$</strong></td>
                  <td class="text-center"> 
                    <button @click="DetailsPayments(objLP.id)" class="button-2 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#DetailAll">Detail</button>   
                   <button @click="MethodsToPayments(objLP.id,objLP.date_init,objLP.date_finish,objLP.time_worked,objLP.payment)" class="button-1 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#PaymentAll">Payment Method</button>
                   <button @click="ChangeIdPayTemporal(objLP.id)" class="btn btn-secondary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#CancelTrx">Cancel</button>  
                  </td>
                </tr>               
              </tbody>
            </table>
          </div>
        </div>
        <!-- End tabla Cuando se seleccionan mas de uno o todos -->
        <!-- modal para confirmar el cancelar -->
        <div class="modal fade" id="CancelTrx"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="CancelTrx" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="CancelTrx">Confirm decision</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">              
                    <div class="col-md-12">     
                      <div>
                        <h3>Are you sure you want to cancel the transaction?</h3> 
                      </div> 
                      <button class="button-1 btn btn-primary m-1 btn-sm" @click="CancelPayment" data-bs-dismiss="modal">Yes</button> 
                      <button class="button-2 btn btn-primary m-1 btn-sm" data-bs-dismiss="modal">Cancel</button> 
                    </div>                          
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!-- end mondal cancelar--->
        <!---------------------------------------------------MODAL Para ver el DETAIL DE VARIOS------------------------------------------------------>
        <div class="modal fade" id="DetailAll"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="DetailAll" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="DetailAll">Detail Transaction</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">              
                    <div class="col-md-12">
                      <div class="col" v-if="ListDetailsPayments.status != 'false'" v-for="(obj, key, index) in ListDetailsPayments.list" :key="index">
                        <p class="text-blue text-start m-2">User: {{ListDetailsPayments.user}}</p>
                        <p class="text-blue text-start m-2">Project: {{obj.name}}</p>
                        <p class="text-blue text-start m-2">Time Worker: {{obj.time_worked}}</p>
                        <p class="text-blue text-start m-2">Rate: {{obj.pay_rate}}</p>
                        <p class="text-blue text-start m-2">Earned: {{obj.payment}}$</p>
                        <hr>
                      </div>
                    </div>  
                    <div>                                    
                      <h5></h5>
                      <button type="button-2 btn btn-primary m-1" class="btn btn-secondary m-3" data-bs-dismiss="modal">Close</button>                                   
                    </div>                                   
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!--FIN MODAL Para ver el DETAIL DE VARIOS-->
        <!--MODAL Para Seleccionar el medotod de pago-->
        <div class="modal fade" id="PaymentAll"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="PaymentAll" aria-hidden="true">  
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="PaymentAll">Payment Method</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">
                    <div class="col-md-12"> 
                      <h5>Please select your payment method</h5> 
                    </div>
                    <div class="row">
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue ">Date to</span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        <strong>{{initMethod}}</strong>
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0">Date from</span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        <strong>{{finishMethod}}</strong>
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0">Total Time</span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        <strong>{{timeMethod}}</strong>
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0">Total Pay</span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        <strong>{{paymentMethod}}$</strong>
                      </div>
                      <div class="col-md-6 m-0 text-start">
                        <span class="text-blue m-0">Payment method</span>
                      </div> 
                      <div class="col-md-6 m-0 text-start">
                        <Multiselect v-model="MultiSelectMethods.value" v-bind="MultiSelectMethods" @select="SelectedMethod" ref="multiselect" class="size-multiselect"></Multiselect>
                      </div>
                      <!-- botonera -->
                      <div>                              
                        <button type="submit" class="button-1 btn btn-primary m-3"  @click="ConfirmMethod"  data-bs-dismiss="modal">
                          Confirm
                        </button> 
                        <button type="btn btn-secondary" class="btn btn-secondary m-3"  data-bs-dismiss="modal" >
                          Cancel
                        </button>
                      </div> 
                      <!-- end botonera -->                                
                    </div>     
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!--FIN MODAL para seleccionar el metodo de pago-->
        <!--Tabla final para CONFIRMAR EL PAGO -->
        <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mb-2 mt-2 bg-body rounded" v-if="ListPending.status != 'false'"><!-- v-show="t2">-->
          <div class="table-responsive">
            <table class="table table-striped responsive align-middle" id="pay3-table">
              <thead>
                <tr class="text-start">
                  <th scope="col"></th>
                  <th scope="col">
                    <span class="text-blue">User</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Date from</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Date to</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Time</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Earned</span>
                  </th> 
                  <th scope="col">
                    <span class="text-blue">Payment Method</span>
                  </th>                  
                   <th scope="col">
                    <span class="text-blue">Date Trx</span>
                  </th> 
                   <th scope="col">
                    <span class="text-blue">Status</span>
                  </th>                  
                   <th scope="col" class="text-center">
                    <span class="text-blue">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody class="mb-special align-middle">               
                <tr class="text-start" v-for="(obj, key, index) in ListPending.list" :key="index">
                  <td class="text-start"><img :src="obj.picture" alt="" width="38" height="38" class="img-avatar"></td>
                  <td class="text-start"><strong>{{obj.user}}</strong></td>
                  <td class="text-start"><strong>{{obj.date_init}}</strong></td>
                  <td class="text-start"><strong>{{obj.date_finish}}</strong></td>
                  <td class="text-start"><strong>{{obj.time_worked}}</strong></td>
                  <td class="text-start"><strong>{{obj.amount}}$</strong></td>
                  <td class="text-start"><strong>{{obj.description}}</strong></td>
                  <td class="text-start"><strong>{{obj.paid_on}}</strong></td>
                  <td class="text-start"> <strong><p class="special-badget-status-p">{{obj.status}}</p></strong></td>
                  <td class="text-center"> 
                    <button @click="DetailsPayments(obj.id)" class="button-2 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#DetailAll2">Detail</button>   
                   <button @click="DetailsPayments(obj.id)" class="button-1 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#PaymentAll2">Confirm</button>
                   <button @click="ChangeIdPayTemporal2(obj.id)" class="btn btn-secondary m-1 btn-sm"  data-bs-toggle="modal" data-bs-target="#CancelTrx2">Cancel</button>  
                  </td>
                </tr>               
              </tbody>
            </table>
          </div>
        </div>
        <!--Fin Tabla -->
        <!-- modal para confirmar el cancelar2 -->
        <div class="modal fade" id="CancelTrx2"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="CancelTrx2" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="CancelTrx2">Confirm decision</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">              
                    <div class="col-md-12">     
                      <div>
                        <h3>Are you sure you want to cancel the transaction?</h3> 
                      </div> 
                      <button class="button-1 btn btn-primary m-1 btn-sm" @click="CancelPayment2" data-bs-dismiss="modal">Yes</button> 
                      <button class="button-2 btn btn-primary m-1 btn-sm" data-bs-dismiss="modal">Cancel</button> 
                    </div>                          
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!-- end mondal cancelar--->
        <!--MODAL Para ver el DETAIL DE VARIOS2-->
        <div class="modal fade" id="DetailAll2"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="DetailAll2" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="DetailAll2">Detail Transaction</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">              
                    <div class="col-md-12">
                      <div class="col" v-if="ListDetailsPayments.status != 'false'" v-for="(obj, key, index) in ListDetailsPayments.list" :key="index">
                        <p class="text-blue text-start m-2">User: {{ListDetailsPayments.user}}</p>
                        <p class="text-blue text-start m-2">Project: {{obj.name}}</p>
                        <p class="text-blue text-start m-2">Time Worker: {{obj.time_worked}}</p>
                        <p class="text-blue text-start m-2">Rate: {{obj.pay_rate}}</p>
                        <p class="text-blue text-start m-2">Earned: {{obj.payment}}$</p>
                        <p class="text-blue text-start m-2">Payment Method: {{ListDetailsPayments.description}}</p>
                        <hr>
                      </div>
                    </div>  
                    <div>                                    
                      <h5></h5>                                         
                      <button type="button-2 btn btn-primary m-1" class="btn btn-secondary m-3" data-bs-dismiss="modal">Close</button>                                   
                    </div>                                   
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!--FIN MODAL Para ver el DETAIL DE VARIOS-->
        <!--MODAL PARA CONFIRMAR PAGO-->
        <div class="modal fade" id="PaymentAll2"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="PaymentAll2" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="PaymentAll2">Confirm Payment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 2-->
                  <div class="row" v-show="ocultar2">
                    <div class="col-md-12 mt-2 mb-2">
                      <div class="col-md-12">
                        <h5>Confirm your payment </h5>
                      </div>
                      <hr>
                      <div class="row mt-3">
                        <div class="col-md-6 mt-2 mb-2" align="left"> 
                          <!--<h6>Trx:087744795</h6>--> 
                        </div>
                        <div class="col-md-6 mt-2 mb-2" align="right">       
                          <h6>Date: {{dateToday}}</h6> 
                        </div>
                      </div>
                      <div class="row mt-0">
                        <div class="col-md-3 m-0 text-start">
                          <span class="text-blue ">Date to:</span>
                        </div>
                        <div class="col-md-3 m-0 text-start">
                          <strong>{{ListDetailsPayments.all.date_init}}</strong>
                        </div>
                        <div class="col-md-3 m-0 text-start">
                          <span class="text-blue m-0">Date from:</span>
                        </div>
                        <div class="col-md-3 m-0 text-start">
                          <strong>{{ListDetailsPayments.all.date_finish}}</strong>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <span class="text-blue m-0">Total Time</span>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <strong>{{ListDetailsPayments.all.time_worked}}</strong>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <span class="text-blue m-0">Method</span>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <strong>{{ListDetailsPayments.all.description}}</strong>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <span class="text-blue m-0">Total Pay</span>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <strong>{{ListDetailsPayments.all.payment}}$</strong>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <span class="text-blue m-0">Status</span>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <strong><p class="special-badget-status-p">{{ListDetailsPayments.all.status_payment}}</p></strong>
                        </div>
                        <div class="col-md-12">
                          <strong><p @click="details = !details">See Details <i class="fa fa-eye"></i></p></strong>
                        </div> 
                        <div class="row" v-show="details">
                          <div class="col-4" v-for="(obj, key, index) in ListDetailsPayments.list" :key="index">
                            <p class="text-blue text-start m-2"><strong>{{obj.name}}</strong></p>
                            <p class="text-blue text-start m-2"><strong>Time Worker:</strong> {{obj.time_worked}}</p>
                            <p class="text-blue text-start m-2"><strong>Rate:</strong> {{obj.pay_rate}}</p>
                            <p class="text-blue text-start m-2"><strong>Payment</strong>: {{obj.payment}}$</p>
                          </div>
                          <!--<div class="col-4">
                            <p class="text-blue text-start m-2"><strong>Project D</strong></p>
                            <p class="text-blue text-start m-2"><strong>Time Worker:</strong> 02:00:00</p>
                            <p class="text-blue text-start m-2"><strong>Rate:</strong> 4</p>
                            <p class="text-blue text-start m-2"><strong>Payment:</strong>8.00$</p>
                          </div>
                          <div class="col-4">
                            <p class="text-blue text-start m-2"><strong>Project E</strong></p>
                            <p class="text-blue text-start m-2"><strong>Worker:</strong> 01:00:00</p>
                            <p class="text-blue text-start m-2"><strong>Rate:</strong> 4</p>
                            <p class="text-blue text-start m-2"><strong>Payment:</strong>4.00$</p>
                          </div>-->
                        </div>                                
                        <div>
                          <div class="alert alert-info mt-1 alert-dismissible fade show" role="alert" align="center">
                            <i class="fas fa-2x fa-exclamation-circle"></i> 
                            <h6>Warning you can't reverse the payment</h6>
                            <!--<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>-->
                          </div> 
                          <div class="input-group mb-1 mt-2">
                            <input id="code_send" type="text" class="form-control" placeholder="Introduce code" aria-label="Introduce code" aria-describedby="button-addon2">
                            <button class="button-1 btn btn-primary" type="button" id="button-addon2" @click="Transaction">Confirm Pay</button>
                          </div>
                          <hr>
                          <button type="btn btn-secondary" class="btn btn-secondary m-3"  data-bs-dismiss="modal">
                            Back
                          </button>
                        </div>                                 
                      </div>                                 
                    </div>                              
                  </div>
                  <!--End Paso 2 -->                            
                  <!-- Paso 3-->
                  <div class="row"  v-show="mostrar1">
                    <div class="col-md-12 mt-2 mb-2">
                      <div class="col-md-12">
                        <h5>Successful payment </h5> 
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <img src="@/assets/img/gifpayment.gif" width="80"  alt="">
                          <h6>
                            Can you see detail of transaction report  <router-link to="report/payments"  data-bs-dismiss="modal"> <strong>here</strong></router-link>
                          </h6>
                        </div>                                       
                      </div>
                    </div>
                  </div>  
                  <!-- End paso 3-->                         
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!--FIN MODAL Para CONFIRMAR EL PAGO-->
        <!--FIN MAS DE UN PROYECTO O TODOS-->
        <!--Cuando se elige solo 1-->
        <!-- Tablas 1-->
        <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mb-2 mt-2 bg-body rounded" v-show="mostrar">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr class="text-start">
                  <th scope="col">
                    <span class="text-blue">User</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Date from</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Date to</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Time</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Earned</span>
                  </th>                   
                   <th scope="col" class="text-center">
                    <span class="text-blue">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody class="mb-special align-middle">               
                <tr class="text-start" v-show="ocultar3">
                  <td class="text-start"><strong>Worker1</strong></td>
                  <td class="text-start"><strong>01/02/2022</strong></td>
                  <td class="text-start"><strong>15/02/2022</strong></td>
                  <td class="text-start"><strong>40:00:00</strong></td>
                  <td class="text-start"><strong>160$</strong></td>
                  <td class="text-center"> 
                    <button class="button-2 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#Detail1">Detail</button>   
                   <button class="button-1 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Payment Method</button>                         
                   <button class="btn btn-secondary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#CancelTrx3">Cancel</button>  
                  </td>
                </tr> 
               <tr class="text-start">
                  <td class="text-start"><strong>Worker1</strong></td>
                  <td class="text-start"><strong>15/02/2022</strong></td>
                  <td class="text-start"><strong>28/02/2022</strong></td>
                  <td class="text-start"><strong>01:00:00</strong></td>
                  <td class="text-start"><strong>4$</strong></td>
                  <td class="text-center"> 
                    <button class="button-2 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#Detail2">Detail</button>   
                   <button class="button-1 btn btn-primary m-1 btn-sm">Payment Method</button>                         
                   <button class="btn btn-secondary m-1 btn-sm"  data-bs-toggle="modal" data-bs-target="#CancelTrx3">Cancel</button>  
                  </td>
                </tr> 
              </tbody>
            </table>
          </div>
        </div>
        <!-- End Tablas -->
        <!-- Modal 0: Detalle de la transasccion-->
        <div class="modal fade" id="Detail1"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="Detail1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="Detail1">Detail of transaction</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">                               
                    <div class="row">
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue "><strong>User</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        Worker1
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue "> <strong>Project</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        Project B
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue "><strong>Date to</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        01/02/2022
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"><strong>Date from</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        15/02/2022
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"> <strong>Total Time</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        40:00:00
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"><strong>Total Pay</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        160$
                      </div>
                      <div>
                        <button type="btn btn-secondary" class="btn btn-secondary m-3"  data-bs-dismiss="modal">
                          Close
                        </button>
                      </div> 
                      <!-- end botonera -->                                
                    </div>     
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <div class="modal fade" id="Detail2"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="Detail2" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="Detail1">Detail of transaction</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">
                    <div class="row">
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue"><strong>User</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        Worker1
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue "><strong>Project</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        Project B
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"><strong>Date to</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        15/02/2022
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"><strong>Date from</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        28/02/2022
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"> <strong>Total Time</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        04:00:00
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"> <strong>Total Pay</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        4$
                      </div>
                      <div>
                        <button type="btn btn-secondary" class="btn btn-secondary m-3"  data-bs-dismiss="modal">
                          Close
                        </button>
                      </div> 
                      <!-- end botonera -->                                
                    </div>     
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!-- modal para confirmar el cancelar3 -->
        <div class="modal fade" id="CancelTrx3"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="CancelTrx3" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="CancelTrx3">Confirm decision</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">              
                    <div class="col-md-12">     
                      <div>
                        <h3>Are you sure you want to cancel the transaction?</h3> 
                      </div> 
                      <button class="button-1 btn btn-primary m-1 btn-sm" @click="mostrar=!mostrar; t1=!t1" data-bs-dismiss="modal">Yes</button> 
                      <button class="button-2 btn btn-primary m-1 btn-sm" data-bs-dismiss="modal">Cancel</button> 
                    </div>                          
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!-- end mondal cancelar--->
        <!--End modal 0 detail -->
        <!-- Modal 1: Seleccionar el Metodo de pago -->
        <div class="modal fade" id="staticBackdrop"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Payment Method</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">
                    <div class="col-md-12"> 
                      <h5>Please select your payment method</h5> 
                    </div>
                    <div class="row">
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue ">Date Start</span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        <strong>01/02/2022</strong>
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0">Date End</span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        <strong>01/02/2022</strong>
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0">Total Time</span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        <strong>40:00:00</strong>
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0">Total Pay</span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        <strong>160$</strong>
                      </div>
                      <div class="col-md-6 m-0 text-start">
                        <span class="text-blue m-0">Payment method</span>
                      </div> 
                      <div class="col-md-6 m-0 text-start">
                        <select class="form-select form-select-sm" required>
                          <option selected>Select</option>
                          <option value="1">Paypal</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <!-- botonera -->
                      <div>                              
                        <button type="submit" class="button-1 btn btn-primary m-3" @click="mostrar2 = !mostrar2; ocultar3 = !ocultar3; mostrar=!mostrar" data-bs-dismiss="modal">
                          Confirm
                        </button> 
                        <button type="btn btn-secondary" class="btn btn-secondary m-3"  data-bs-dismiss="modal" >
                          Cancel
                        </button>
                      </div>
                      <!-- end botonera -->                                
                    </div>     
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!-- End modal 1-->
        <!-- Segunda tabla -->
        <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mb-2 mt-2 bg-body rounded" v-show="mostrar2">
          <div class="table-responsive">
            <table class="table table-striped responsive">
              <thead>
                <tr class="text-start">
                  <th scope="col">
                    <span class="text-blue">User</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Date from</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Date to</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Time</span>
                  </th>
                  <th scope="col">
                    <span class="text-blue">Earned</span>
                  </th>   
                  <th scope="col">
                    <span class="text-blue">Method</span>
                  </th> 
                  <th scope="col">
                    <span class="text-blue">Payment Date</span>
                  </th> 
                  <th scope="col">
                    <span class="text-blue">Status</span>
                  </th>               
                  <th scope="col" class="text-center">
                    <span class="text-blue">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody class="mb-special align-middle">               
                <tr class="text-start">
                  <td class="text-start"><strong>Worker1</strong></td>
                  <td class="text-start"><strong>01/02/2022</strong></td>
                  <td class="text-start"><strong>15/02/2022</strong></td>
                  <td class="text-start"><strong>40:00:00</strong></td>
                  <td class="text-start"><strong>160$</strong></td>
                  <td class="text-start"><strong>Paypal</strong></td>
                  <td class="text-start"><strong>10/02/2022</strong></td>
                  <td class="text-start"> <strong><p class="special-badget-status-p">Procesing</p></strong></td>
                  <td class="text-center"> 
                    <button class="button-2 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#Detail3">Detail</button>   
                    <button class="button-1 btn btn-primary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">Confirm</button>                         
                    <button class="btn btn-secondary m-1 btn-sm" data-bs-toggle="modal" data-bs-target="#CancelTrx4">Cancel</button>  
                  </td>
                </tr> 
              </tbody>
            </table>
          </div>
        </div>
        <!-- End tabla -->
        <!-- Modal 1: Detalles de pago -->
        <div class="mod78al fade" id="Detail3"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="Detail3" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="Detail1">Detail of transaction</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">
                    <div class="row">
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue "> <strong>User</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        Worker1
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue "><strong>Project</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        Project B
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue "><strong>Date to</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        15/02/2022
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"><strong>Date from</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        28/02/2022
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"><strong>Total Time</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        40:00:00
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"><strong>Status</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        Procesing...
                      </div>
                      <div class="col-md-4 m-0 text-start">
                        <span class="text-blue m-0"><strong>Total Pay</strong></span>
                      </div>
                      <div class="col-md-8 m-0 text-start">
                        160$
                      </div>
                      <div>
                        <button type="btn btn-secondary" class="btn btn-secondary m-3"  data-bs-dismiss="modal">
                          Close
                        </button>
                      </div>
                      <!-- end botonera -->                                
                    </div>     
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!-- End modal 3 ---------------->
        <!-- Modal 2: Confirmar el pago -->
        <div class="modal fade" id="staticBackdrop2"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Confirm Payment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 2-->
                  <div class="row" v-show="ocultar2">
                    <div class="col-md-12 mt-2 mb-2">
                      <div class="col-md-12">
                        <h5>Confirm your payment </h5>
                      </div>
                      <hr>
                      <div class="row mt-3">
                        <div class="col-md-6 mt-2 mb-2" align="left"> 
                          <h6>Trx:0001484795</h6>  
                        </div>
                        <div class="col-md-6 mt-2 mb-2" align="right">       
                          <h6>Date: 10/02/2022</h6> 
                        </div>
                      </div>
                      <div class="row mt-0">
                        <div class="col-md-3 m-0 text-start">
                          <span class="text-blue ">Date to:</span>
                        </div>
                        <div class="col-md-3 m-0 text-start">
                          <strong>01/02/2022</strong>
                        </div>
                        <div class="col-md-3 m-0 text-start">
                          <span class="text-blue m-0">Date from:</span>
                        </div>
                        <div class="col-md-3 m-0 text-start">
                          <strong>15/02/2022</strong>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <span class="text-blue m-0">Total Time</span>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <strong>40:00:00</strong>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <span class="text-blue m-0">Method</span>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <strong>Paypal</strong>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <span class="text-blue m-0">Total Pay</span>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <strong>160$</strong>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <span class="text-blue m-0">Status</span>
                        </div>
                        <div class="col-md-6 m-0 text-start">
                          <strong><p class="special-badget-status-p">Procesing</p></strong>
                        </div>                               
                        <div>
                          <div class="alert alert-info mt-1 alert-dismissible fade show" role="alert" align="center">
                            <i class="fas fa-2x fa-exclamation-circle"></i> 
                            <h6>Warning you can't reverse the payment</h6>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div> 
                          <div class="input-group mb-1 mt-2">
                            <input type="text" class="form-control" placeholder="Introduce code" aria-label="Introduce code" aria-describedby="button-addon2">
                            <button class="button-1 btn btn-primary" type="button" id="button-addon2" @click="mostrar1 = !mostrar1; ocultar2=!ocultar2">Confirm Pay</button>
                          </div>
                          <hr>
                          <button type="btn btn-secondary" class="btn btn-secondary m-3"  data-bs-dismiss="modal">
                            Back
                          </button>
                        </div>                                 
                      </div>                                 
                    </div>                              
                  </div>
                  <!--End Paso 2 -->                            
                  <!-- Paso 3-->
                  <div class="row"  v-show="mostrar1">
                    <div class="col-md-12 mt-2 mb-2">
                      <div class="col-md-12">
                        <h5>Successful payment </h5> 
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <img src="@/assets/img/gifpayment.gif" width="80"  alt="">
                          <h6>
                            Can you see detail of transaction report  <router-link to="report/payments"  data-bs-dismiss="modal"> <strong>here</strong></router-link>
                          </h6>
                        </div>                                       
                      </div>
                    </div>
                  </div>
                  <!-- End paso 3-->                         
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!-- End modal 2-->
        <!-- modal para confirmar el cancelar3 -->
        <div class="modal fade" id="CancelTrx4"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="CancelTrx4" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="CancelTrx4">Confirm decision</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">                         
                  <!-- Paso 1-->
                  <div class="row">              
                    <div class="col-md-12">     
                      <div>
                        <h3>Are you sure you want to cancel the transaction?</h3> 
                      </div> 
                      <button class="button-1 btn btn-primary m-1 btn-sm" @click="mostrar2=!mostrar2; ocultar3=!ocultar3; mostrar=!mostrar" data-bs-dismiss="modal">Yes</button> 
                      <button class="button-2 btn btn-primary m-1 btn-sm" data-bs-dismiss="modal">Cancel</button> 
                    </div>                          
                  </div>                                 
                </div>                          
              </div>                         
            </div>
          </div>
        </div>
        <!-- end mondal cancelar--->
      </div>   
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import Multiselect from '@vueform/multiselect'
  import moment from 'moment-timezone';
  import $ from 'jquery';

  export default
  {
    name: 'Payments',
    components: 
    {
      Multiselect
    },
    data: () => (
    {
      t1:true,  
      t2:false,  
      boton: false,
      mostrarvarios:false,
      mostrar: false,
      mostrar2: false,
      mostrar1: false,          
      ocultar1: true,    
      ocultar3: true,     
      ocultar2: true,    
      details: false,
      idPayTemporal: '',
      idPayTemporal2: '',
      dateToday: new Date(),
      MultiSelectMembers: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions: true
      },
      MultiSelectMethods: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      ListMembers: 
      {
        status: "false",
        list: {}
      },
      ListSelect: 
      {
        status: "false",
        list: {},
        msg: ""
      },
      ListPayments: 
      {
        status: "false",
        list: {}
      },
      ListDetailsPayments: 
      {
        status: "false",
        list: {},
        user: "",
        description: "",
        all: ""
      },
      ListPending: 
      {
        status: "false",
        list: {}
      },
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      range: 
      {
        start: null,//new Date(),
        end: null//new Date()
      },
      PostPay: 
      {
        date_init: null,//new Date(),
        date_finish: null,//new Date(),
        timezone: moment.tz.guess(true),
        id_session: ''
      },
      PostPay2: 
      {
        date_init: null,//new Date(),
        date_finish: null,//new Date(),
        timezone: moment.tz.guess(true),
        id_session: '',
        id_project: ''
      },
      PostSelect: 
      {
        date_init: null,//new Date(),
        date_finish: null,//new Date(),
        timezone: moment.tz.guess(true),
        id_session: ''
      },
      PostList:
      {
        timezone: moment.tz.guess(true),
        id_session: ''
      },
      PostList2:
      {
        timezone: moment.tz.guess(true),
      },
      PostDelete: 
      {
        id_payment: ''
      },
      PostCancel: 
      {
        id_payment: ''
      },
      PostDetails: 
      {
        id_payment: '',
        timezone: moment.tz.guess(true),
      },
      PostTransaction: 
      {
        id_payment: '',
        id_type_payment: ''
      },
      PostPending: 
      {
        timezone: moment.tz.guess(true),
      },
      PostPending2: 
      {
        id_session: '',
        timezone: moment.tz.guess(true),
      },
      PostTransactionCode:
      {
        id_payment: '',
        code_transaction: ''
      },
      //Methods
      initMethod: '',
      finishMethod: '',
      timeMethod: '',
      paymentMethod: '',
      idTypeMethod: '',
      counterTr: 0
    }),
    methods: 
    {
      Transaction()
      {
        this.PostTransactionCode.code_transaction = $("#code_send").val();

        Api.post("/transaction/code", this.PostTransactionCode).then((result) => 
        {
          this.mostrar1=!this.mostrar1;
          this.ocultar2=!this.ocultar2;
          if(this.MultiSelectMembers.value == "" || this.MultiSelectMembers.value == null)
          {
            this.ListPayment(2);
            this.PaymentPending(2);
          }
          else
          {
            this.ListPayment(1);
            this.PaymentPending(1);
          }

          if(this.ValidateFields() != true)
          {  
            this.SendSelect();
          }
        })
      },
      CancelPayment2()
      {
        this.PostCancel.id_payment = this.idPayTemporal2;

        Api.post("/transaction/cancel", this.PostCancel).then((result) => 
        {
            if(result.data.status == 'true') 
            {
              if(this.MultiSelectMembers.value == "" || this.MultiSelectMembers.value == null)
              {
                this.ListPayment(2);
                this.PaymentPending(2);
              }
              else
              {
                this.ListPayment(1);
                this.PaymentPending(1);
              }
            }
        })
      },
      ChangeIdPayTemporal2(id)
      {
        this.idPayTemporal2 = id;
      },
      //List payments pending
      PaymentPending(flag)
      {
        if(flag == 0 || flag == 2) 
        {
          Api.post("/payment/pending", this.PostPending).then((result) => 
          {
            this.ListPending.status = result.data.status

            if (this.ListPending.status == "true") 
            {
              if (flag == 2) 
              {
                var table = $('#pay3-table').DataTable();
                table.destroy();
              }

              this.ListPending.list = result.data.list
              this.PostTransactionCode.id_payment = this.idPayTemporal;

              this.$nextTick(() => 
              {
                this.InitDatatable3()
              })
            }
          })
        }
        else
        {
          Api.post("/payment/pending", this.PostPending2).then((result) => 
          {
            this.ListPending.status = result.data.status

            if (this.ListPending.status == "true") 
            {
              var table = $('#pay3-table').DataTable();
              table.destroy();

              this.ListPending.list = result.data.list
              this.PostTransactionCode.id_payment = this.idPayTemporal;

              this.$nextTick(() => 
              {
                this.InitDatatable3()
              })
            }
          })
        }
      },
      ConfirmMethod()
      {
        this.PostTransaction.id_payment = this.idPayTemporal;
        this.PostTransaction.id_type_payment = this.idTypeMethod;

        Api.post("/transaction/insert", this.PostTransaction).then((result) =>
        {
          if(result.data.status == 'true') 
          {
            if(this.MultiSelectMembers.value == "" || this.MultiSelectMembers.value == null)
            {
              this.ListPayment(2);
              this.PaymentPending(2);
            }
            else
            {
              this.ListPayment(1);
              this.PaymentPending(1);
            }
          }
        })
      },
      MethodsToPayments(id,init,finish,time,payment)
      {
        this.idPayTemporal = id;
        this.initMethod = init;
        this.finishMethod = finish;
        this.timeMethod = time;
        this.paymentMethod = payment;
      },
      GetMethods()
      {
        Api.get('/payment/types').then((result) => 
        {
          if (result.data.status == "true") 
          {
            if (result.data.list != "") 
            {
              var methods = []
              methods = result.data.list
              let paymethods = [];

              for (var i = 0; i < methods.length; i++) 
              {
                paymethods.push(
                {
                  value: methods[i].type,
                  label: methods[i].description
                });
              }
              this.MultiSelectMethods.options = paymethods;
            }
          }
        });
      },
      SelectedMethod(option) 
      {
        this.idTypeMethod = option;
      },
      DetailsPayments(id)
      {
        this.idPayTemporal = id;
        this.PostDetails.id_payment = this.idPayTemporal;
        this.PostTransactionCode.id_payment = id;

        Api.post("/payment/details", this.PostDetails).then((result) => 
        {
          this.ListDetailsPayments.status = result.data.status

          if (this.ListDetailsPayments.status == "true") 
          {
            this.ListDetailsPayments.list = result.data.list
            this.ListDetailsPayments.user = result.data.user
            this.ListDetailsPayments.all = result.data
            if(result.data.description)
            {
              this.ListDetailsPayments.description = result.data.description
            }
          }
        })
      },
      ChangeIdPayTemporal(id)
      {
        this.idPayTemporal = id;
      },
      CancelPayment()
      {
        this.PostDelete.id_payment = this.idPayTemporal;

        Api.post("/payment/delete", this.PostDelete).then((result) => 
        {
          if(result.data.status == 'true') 
          {
            if(this.MultiSelectMembers.value == "" || this.MultiSelectMembers.value == null)
            {
              if(this.ValidateFields() != true)
              {  
                this.SendSelect();
              }

              this.ListPayment(2);
              this.PaymentPending(2);
            }
            else
            {
              if(this.ValidateFields() != true)
              {  
                this.SendSelect();
              }

              this.ListPayment(1);
              this.PaymentPending(1);
            }
          }
        })
      },
      ConfirmProjects()
      {
        this.PostPay2.id_project = '';

        let myid = "";
        var table = $('#pay-table').DataTable();

        for(var i=0; i<table.rows('.selected', { selected: true } ).count(); i++) 
        {
          myid += table.rows('.selected', { selected: true } ).data()[i][0];
          if(i < (table.rows('.selected', { selected: true } ).count()-1))
          {
            myid += ",";
          }
        }

        $("#totalProjects").val(myid);

        table.rows( '.selected' ).nodes().to$() .removeClass( 'selected' );
        $("#button-confirm").css({"visibility":"hidden"});

        if(this.ListSelect.list.length != $("#counterSelect").val())
        {
          this.PostPay2.id_project = $("#totalProjects").val();

          Api.post("/payment/insert", this.PostPay2).then((result) => 
          {
            if(result.data.status == 'true') 
            {
              if(this.ValidateFields() != true)
              {  
                this.SendSelect();
              }

              this.ListPayment(1);
            }
          })
        }
        else 
        {
          this.PostPay2.id_project = '';

          Api.post("/payment/insert", this.PostPay).then((result) => 
          {
            if(result.data.status == 'true') 
            {
              if(this.ValidateFields() != true)
              {  
                this.SendSelect();
              }

              this.ListPayment(1);
            }
          })
        }
      },
      //List Data in Second Table (To select payment method)
      ListPayment(flag)
      {
        if(flag == 0 || flag == 2) 
        {
          Api.post("/payment/list", this.PostList2).then((result) => 
          {
            this.ListPayments.status = result.data.status

            if (this.ListPayments.status == "true") 
            {

              if (flag == 2) 
              {
                var table = $('#pay2-table').DataTable();
                table.destroy();
              }

              this.ListPayments.list = result.data.paymets

              this.$nextTick(() => 
              {
                this.InitDatatable2()
              })
            }
          })
        }
        else
        {
          Api.post("/payment/list", this.PostList).then((result) => 
          {
            this.ListPayments.status = result.data.status

            if (this.ListPayments.status == "true") 
            {
              var table = $('#pay2-table').DataTable();
              //Exists Datatable?
              //if($.fn.dataTable.isDataTable('#pay2-table'));
              table.destroy();
              this.ListPayments.list = result.data.paymets
              this.$nextTick(() => 
              {
                this.InitDatatable2()
              })
            }
          })
        }
      },
      //Check Fields
      ValidateFields() 
      {
        if(this.MultiSelectMembers.value == "" || this.MultiSelectMembers.value == null || this.PostSelect.date_init == null || this.PostSelect.date_finish == null)
        {
          return true
        }
      },
      //Get Members
      GetMembers() 
      {
        // Sam pading value to start with 0. eg: 01, 02, .. 09, 10, ..
        function padValue(value) 
        {
            return (value < 10) ? "0" + value : value;
        }

        var f = new Date();
        this.dateToday = padValue(f.getDate()) + "-"+ padValue((f.getMonth()+1))+ "-" +f.getFullYear();

        Api.post("/member/list", "").then((result) => 
        {
          this.ListMembers.status = result.data.status

          if (this.ListMembers.status == "true") 
          {
            this.ListMembers.list = result.data.member

            for (var i = 0; i < this.ListMembers.list.length; i++) 
            {
              if (this.ListMembers.list[i].description == "Worker") 
              {
                var options = 
                {
                  value: this.ListMembers.list[i].id_session,
                  label: this.ListMembers.list[i].email,
                  icon: this.ListMembers.list[i].picture,
                  color: this.ListMembers.list[i].color
                }
                this.MultiSelectMembers.options.push(options)
              }
            }
          }
        })
      },
      //First table to select projects by members and date 
      SendSelect()
      {
        Api.post("/payment/select", this.PostSelect).then((result) => 
        {          
          this.ListSelect.status = result.data.status
          this.ListSelect.msg = result.data.msg

          if (this.ListSelect.status == "true") 
          {
            //Exists Datatable?
            if ($.fn.dataTable.isDataTable('#pay-table'))
            {
              var table = $('#pay-table').DataTable();
              table.destroy();
            }
            
            this.ListSelect.list = result.data.payments

            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
          else
          {
            this.$moshaToast( ''+this.$filters.OnlyLetters(this.ListSelect.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
          }
        })
      },
      //Selected Member method
      SelectedMember(option) 
      {
        this.myid = option;

        this.PostPay.id_session = this.myid;
        this.PostPay2.id_session = this.myid;
        this.PostSelect.id_session = this.myid;
        this.PostList.id_session = this.myid;
        this.PostPending2.id_session = this.myid;

        this.ListPayment(1);
        this.PaymentPending(1);

        if(this.ValidateFields() != true)
        {  
          this.SendSelect();
        }
      },
      //Clear member method
      ClearMember(option) 
      {
        this.ListPayment(2);
        this.PaymentPending(2);
      },
      //Total earned by projects (first table)
      SumTotalProjects()
      {
        let total = 0;
        for (var i = 0; i < this.ListSelect.list.length; i++) 
        {
          total += Number(this.ListSelect.list[i].payment);
        }
        return total.toFixed(2) + "$";
      },
      //Init first table
      InitDatatable() 
      {
        var table = $('#pay-table').DataTable(
        {
          dom: 'lft',
          responsive: true,
          destroy: true,
          lengthChange: false,
          selected: true, // true, false, undefined
          aaSorting: [ [0,'asc'] ]
        });

        if(this.counterTr == 0)
        {
          $('#pay-table tbody tr').click(function () 
          {
            if ( $(this).hasClass('selected') ) 
            {
              $(this).removeClass('selected');
            }
            else 
            {
              $(this).addClass('selected');
            }
              
            $("#counterSelect").val(table.rows('.selected')[0].length);

            if(table.rows('.selected')[0].length > 0)
            {
              $("#button-confirm").css({"visibility":"visible"});
            }
            else
            {
              $("#button-confirm").css({"visibility":"hidden"});
            }
          });
        }
        else
        {
          $("#pay-table tbody tr").unbind('click');
          $('#pay-table tbody tr').click(function () 
          {
            if ( $(this).hasClass('selected') ) 
            {
              $(this).removeClass('selected');
            }
            else 
            {
              $(this).addClass('selected');
            }
              
            $("#counterSelect").val(table.rows('.selected')[0].length);

            if(table.rows('.selected')[0].length > 0)
            {
              $("#button-confirm").css({"visibility":"visible"});
            }
            else
            {
              $("#button-confirm").css({"visibility":"hidden"});
            }
          });
        }
        this.counterTr ++;
      },
      //Init second table
      InitDatatable2() 
      {
        var table2 = $('#pay2-table').DataTable(
        {
          dom: 'lftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      //Init third table
      InitDatatable3() 
      {
        var table = $('#pay3-table').DataTable(
        {
          data: this.ListPayment.list,
          dom: 'lftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      //Function to click in date input
      onDayClick(day) 
      {
        this.PostPay.date_init = this.range.start;
        this.PostPay.date_finish = this.range.end;

        this.PostPay2.date_init = this.range.start;
        this.PostPay2.date_finish = this.range.end;

        this.PostSelect.date_init = this.range.start;
        this.PostSelect.date_finish = this.range.end;
        
        if(this.ValidateFields() != true)
        {
          this.SendSelect();
        }
      },
    },
    mounted() 
    {
      this.GetMembers(),
      this.GetMethods(),
      this.ListPayment(0),
      this.PaymentPending(0)
    }
  }
</script>

<style scoped>
  a{
    color:#0D3E67;
  }
  .special-badget-status-p {
    background-color: #98DAFF;
    color: #0D3E67;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 50px;
    font-weight: bold;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .button-2{
    background-color: #3f82b9;
    border: 1px solid #3f82b9;
  }
</style>
